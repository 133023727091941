<template>
  <div class="container">
    <section class="banner"></section>
    <section>
      <div class="checkbox">
        <span>分类：</span>
        <el-checkbox-group v-model="product">
          <el-checkbox-button v-for="(item,index) in productOptions"  :label="item.value" :key="index">{{item.label}}</el-checkbox-button>
        </el-checkbox-group>
      </div>
     
    </section>
    <section>
      <div class="content">
        <div class="crumbs">
          当前位置： 全部
        </div>
        <div class="search">
          <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="keywords">
          </el-input>
        </div>
      </div>
    </section>
    <section>
      <div class="module">
        <ul class="two-columns">
          <li class="item" v-for="(item,index) in newsList" :key="index">
            <router-link :to="{path:'/news/detail?id='+item.news_id}" class="card">
              <div class="thumbnail">
                <img :src="item.image" class="newImage" alt="">
              </div>
              <div class="content">
                <div class="title">{{ item.title }}</div>
                <div class="describe">{{ item.brief }}</div>
                <div class="bottom">
                  <span class="time">{{ item.created_at }}</span>
                  <i class="el-icon-view"></i>
                </div>
              </div>
            </router-link>  
          </li>
        </ul>
      </div>
      <div class="page-Wrap">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :current-page="pageProps.pageNum"
          :page-sizes="[5, 10, 50, 100]"
          layout="prev, pager, next, jumper"
          >
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import { getNews } from "@/api/syllabus/index";

export default {
  data() {
    return {
      currentPage3:1,
      complete:[''],
      product:[''],
      keywords:'',
      productOptions:[
      {
          value: '',
          label: '全部'
        },
      ],
      newsList:[],
      pageProps: {
          records:[],
          total: 0,
          pageSize: 20,
          pageNum: 1
        }
    };
  },
  methods:{
    handleSizeChange(val){
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val){
      console.log(`当前页: ${val}`);

    },
  },



  mounted () {
    this.getNews();

  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },

  },

  methods: {
    
    getNews(){
        
      getNews({list_rows:this.pageProps.pageSize,page : this.pageProps.pageNum}).then(res => {
          console.log(res);
        if(res.code==200){
          this.newsList = res.data.news.data;
          this.pageProps.total=res.data.news.total;
        }
      })
      },

  }
};
</script>

<style lang="scss" scoped>

.container {
  .banner{
    width: 100%;
    height: 200px;
    background: url('../../assets/image/banner1.png');
  }

  
  .checkbox{
    display: flex;
    border-bottom: 1px dashed #e2e2e2;
    padding: 0 10px;
    height: 62px;
    background: #fff;
    align-items: center;
    span{
      font-size: 14px;
      color:#999;
    }
    /deep/.el-checkbox-button{
      padding: 0 5px;
        margin: 5px 35px 5px 0;
        
        cursor: pointer;
        transition: all .3s;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border: none;
        box-sizing: border-box;
        height: 24px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-direction: row;
    }
    /deep/.el-checkbox-button__inner{
      border: none;
      font-size: 14px;
      color:#999;
      height: 24px;
      box-sizing: border-box;
      line-height: 24px;
      padding:0 5px;
      border-radius: 4px;
      &:hover{
        color: #266fff;
      }
    }
    /deep/.is-checked .el-checkbox-button__inner{
      background-color: #266fff;
      color: #fff;
    }
   
  }
  .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 0 16px;
    box-sizing: border-box;
    .crumbs{
    font-size: 14px;
    color: #999;
    }
    .search{
      width: 220px;
      height: 32px;
      /deep/.el-input__inner{
        border-radius: 16px;
      }
    }
  }

}
.page-Wrap{
  padding: 40px 0;
    text-align: center;
}

.module {
  display: flex;
  justify-content: center;
}
 .two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  list-style: none;
  padding: 0;
  margin: 0;
}
 .item {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
 .item:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}
 .card {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
}
 .thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 40%;
  overflow: hidden;
}
 .newImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 .content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
 .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.2;
  height: 2.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
 .describe {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
  line-height: 1.2;
  height: 3.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
 .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 .time {
  font-size: 12px;
  color: #999;
}
</style>
